.insight-box-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 40px;
}

.insight-section {
  display: flex;
  flex-direction: column;
  width: 48%;
}

@media (max-width: 768px) {
  .insight-box-container {
    flex-direction: column;
    align-items: center;
  }

  .insight-section {
    width: 100%;
  }
}

.section-title {
  font-size: 24px;
  margin-bottom: 16px;
}

.insight-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.insight-item {
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #f8f8f8;
  transition: all 0.2s ease-in-out;
}

.insight-item:hover {
  transform: translateY(-4px);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
}
p {
  font-weight: bold;
}

body {
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  background-color: #fff !important;
}

.service_title {
  margin-top: 75px;
  margin-bottom: 130px;
  letter-spacing: 55px;
  color: #000 !important;
}
.service_title > span {
  color: #c81880 !important;
}

.our_mission {
  display: flex;
}
.our_mission_content {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 1.7;
  font-size: 24px;
  color: #444;
}
.our_mission_content > p {
  text-align: start;
}
.our_mission_content,
.our_mission_img {
  width: 50%;
}
.our_mission_img > img {
  width: 100%;
}

*,
::after,
::before {
  ---webkit-box-sizing: border-box;
  --o--box-sizing: border-box;
  --moz--box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #333; */
  font-family: Georgia, Serif;
  font-weight: 300;
  font-size: 1rem;
}
span {
  margin: 0;
  padding: 0;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  vertical-align: baseline;
}
ol,
ul {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}
.scontainer {
  width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
a {
  color: inherit;
  outline: 0;
  text-decoration: none;
}
a {
  -webkit-text-decoration-skip: objects;
  background-color: transparent;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Start main */

.home {
  margin-top: 85px;
}
main .slider {
}
main .slider img {
  width: 100%;
  height: 500px;
}
main h1 {
  color: #333;
  font-size: 58px;
}
main .cont__inf h2 {
  color: rgba(61, 61, 180, 0.932);
  font-size: 34px;
  font-weight: 800;
}
main .cont__inf span {
  font-size: 24px;
  font-weight: 600;
  color: #0056ca;
}
main .cont__inf span:last-child {
  display: block;
  line-height: 1.6;
  color: #415c82;
  margin-top: 20px;
}
main .serch__bar input {
  width: 20%;
}
.about__inf h1 {
  font-size: 58px;
  font-weight: bold;
  font-weight: 800;
}
.about__inf p {
  color: #415c82;
  font-size: 20px;
}
.about__inf p span {
  font-weight: bold;
}
.how__work__inf h2 {
  font-size: 34px;
  font-weight: 600;
  color: rgba(61, 61, 180, 0.932);
}
.how__work__inf p {
  font-size: 22px;
  line-height: 1.6;
  margin: 20px 0 10px;
  color: #415c82;
}
/* End main */

footer {
  text-align: center;
  background-color: #0f131e;
  color: #fff;
  padding: 40px;
}

.counter {
  background-color: aliceblue;
  color: black;
  width: 100%;
  padding: 50px;
}

.items {
  display: flex;
}
.count_item {
  width: 25%;
}

.footer {
  background-color: #f9f9f9;
  margin-top: 40px;
  border-top: 1px solid rgb(243, 241, 241);
}

.footer_container {
  padding-top: 40px;
  padding-bottom: 60px;
}

.inf_footer {
  display: flex;
  justify-content: space-around;
}

.inf_footer .ser_foot {
  display: flex;
  flex-direction: column;
  line-height: 2.9;
}

.end_footer {
  margin-top: 40px;
  text-align: center;
}

@media (max-width: 833px) {
  .inf_footer {
    flex-direction: column;
  }
  .inf_footer .ser_foot,
  .job_foot {
    margin-bottom: 20px;
  }
  .about_foot > div {
    display: flex;
    gap: 30px;
    align-items: center;
    align-content: center;
    font-weight: bold;
    margin: -2px 0 0 0;
  }
  .about_foot > span {
    font-weight: bold;
  }
}

.imgs_service_in {
  background-image: url(/public/inslid.jpeg);
}

.intro_about_insig h1 {
  text-align: center;
  font-size: 2.8rem;
  line-height: 1.3;
  font-weight: 500;
  color: #00338d;
  margin-top: 20px;
  margin-bottom: 80px;
}
.elems_insig {
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
  margin-top: 40px;
  text-align: left;
}

.elems_insig .elem_insig {
  width: 32%;
  margin-bottom: 10px;
}

.impor_insig {
  margin: 40px 0;
  text-align: left;
}
.impor_insig h1 {
  color: #333;
  font-size: 50px;
  font-weight: bold;
}
.impor_insig p {
  color: #888;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.8;
  padding: 10px;
  margin: 0;
}

.hyper_sec {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  height: 500px;
  width: 900px;
  border-radius: 5px;
  margin: auto;
  padding: 40px;
  -webkit-box-shadow: 0 0 10px #ddd;
  -moz-box-shadow: 0 0 10px #ddd;
  box-shadow: 0 0 10px #ddd;
}
@media (max-width: 833px) {
  .hyper_sec {
    width: 600px;
    flex-direction: column;
  }
}
.right_hyper {
  border-left: 1px solid #ccc;
  padding-left: 60px;
}
.left_hyper,
.right_hyper {
  width: 50%;
  line-height: 1.7;
}
@media (max-width: 833px) {
  .left_hyper {
    width: 100%;
    text-align: left;
  }
  .right_hyper {
    display: none;
  }
}
.left_hyper p,
.right_hyper p {
  font-size: 18px;
}
.elem_insig {
  width: 340px;
  height: 340px;
}
.elem_insig img {
  height: 100%;
  width: 100%;
}

.questions h2 {
  display: inline;
  position: relative;
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.questions h2::before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0%;
  height: 4px;
  background-color: #333;
  transition: width 0.3s ease-in-out;
}

.questions h2:hover::before {
  width: 100%;
}

@media (max-width: 833px) {
  .questions h2 {
    font-size: 25px;
  }
}

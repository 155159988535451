/* Start Landing */
main[role='main'] {
  margin-top: 8rem;
  position: relative;
  z-index: 1;
}
main {
  display: block;
}

.HeroSlider {
  background-color: #144d75;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-height: 500px;
  max-width: 1920px;
  position: relative;
  width: 100%;
}
.slick-list,
.slick-slider {
  display: block;
  position: relative;
}
.slick-slider {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.HeroSlider {
  color: #fff;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translateZ(0);
}
.slick-list {
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.slick-list,
.slick-slider {
  display: block;
  position: relative;
}
.slick-opacity {
  opacity: 1;
  width: 1920px;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translateZ(0);
}

element {
  opacity: 1;
  width: 1920px;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translateZ(0);
}
.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
}
.slick-slide {
  width: 1920px;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  opacity: 1;
}
.slick-initialized .slick-slide {
  display: block;
}
.HeroSlider-slide {
  height: 500px;
  padding: 10rem 5rem 5rem;
  position: relative;
}
.slick-slide {
  display: none;
  float: left;

  min-height: 1px;
}
.HeroSlider-cover {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.HeroSlider-image {
  background-size: cover;
  display: block;
  height: 100%;
  width: 100%;
}
.imgs {
  background-image: url(/public/Victoria.jpg) !important;
}

.HeroSlider-cover span {
  background-color: aqua;
  width: 100%;
  height: 500px;
}

/* End Landing */

/* Seconed Section */
.s_s1 {
  position: relative;
  z-index: 1;
}

.section h2 {
  padding-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.section .section-container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.card {
  border-radius: 6px;
}
.inf-card {
  padding: 0 0 0 20px;
}
.artical .card img {
  width: 453.33px;
  height: 255px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.artical .card .inf-card .frist {
  font-size: 2.125rem;
  display: block;
  word-break: break-word;
  margin: 20px 0;
  color: #2d2d2d;
}

.artical .card:hover .frist > span {
  border-bottom: 2px solid #e0301e;
  color: #e0301e;
  cursor: pointer;
}

.artical .card .inf-card .second_span {
  display: block;
  font-size: 1.125rem;
  line-height: 1.5em;
  font-family: Arial, Sans-Serif;
  margin: 10px 0 10px;
  padding: 0;
  border: 0;
  font-weight: 300;
  color: #464646;
}

/* third section */

.explore {
  background-color: #094191;
  height: 150px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 833px) {
  .explore {
    display: none;
  }
}

.title-container {
  min-height: 150px;
  margin: 0px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 6px;
}
.explore span {
  display: block;
  line-height: 1.25;
  /* color: #2d2d2d; */
  color: #fff;
  padding: 0 0 0 90px;
}
.explore .title-explore {
  padding-bottom: 8px;
  position: relative;
  font-size: 2.125rem;
}

.explore .subtitle {
  font-size: 1.125rem;
}

.btn-container {
  text-align: center;
  position: relative;
}

.btn-More {
  border: 1px solid #fff;
  color: #fff;
  top: 0;
  right: 335px;
  display: inline-block;
  padding: 0.75rem 2rem 0.75rem 1.5rem;
  white-space: nowrap;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.6s ease;
  position: relative;
}

.explore:hover .btn-More {
  background-color: #e0301e;
  border-color: #e0301e;
}

/* Start industries */

.industries_container {
  background-color: #f9f9f9;
}

@media (max-width: 833px) {
  .industries_container {
    margin-top: 60px;
  }
}
.industries {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 60px;
  font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
  cursor: pointer;
}
.industries > h2,
.title_service > h2 {
  text-align: center;
  font-size: 2.8rem;
  line-height: 1.3;
  font-weight: 500;
  color: #00338d;
  margin-top: 20px;
  margin-bottom: 40px;
}
.industries .cont_industries {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}
@media (max-width: 833px) {
  .industries .cont_industries {
    flex-direction: column;
  }
}
.cont_industries .inf_industries {
  width: 31.333333%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px 20px;
  border: 1px solid #f9f9f9;
  border-radius: 6px;
  transition: all 0.3s;
}
.contImg {
  margin-bottom: 10px;
}
.contImg img {
  width: 55px;
}
@media (max-width: 833px) {
  .cont_industries .inf_industries {
    width: 100%;
    text-align: center;
  }
}
.inf_industries h2 {
  color: #333;
  font-weight: bold;
  font-size: 25px;
}

.inf_industries:hover {
  border-color: #094191;
  background-color: #fff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.inf_industries p {
  color: #666;
  line-height: 1.7;
  text-align: justify;
}

.button_industries,
.button_service {
  text-align: center;
  margin-top: 30px;
}

.button_industries span,
.button_service span {
  display: inline-block;
  border: 1px solid #00338d;
  border-radius: 3px;
  padding: 10px 40px;
  font-size: 1.3rem;
  color: #333;
  font-weight: bold;
  transition: all 0.3s;
}
.button_industries span:hover,
.button_service span:hover {
  background-color: #094191;
  color: #f9f9f9;
}
/* End industries */

/* Start Service */
.services {
  /* padding-top: 115px; */
}
.service {
  padding-top: 60px;
  padding-bottom: 60px;
  cursor: pointer;
  text-align: center;
}
.container_service {
  display: flex;
  flex-direction: column;
}
.title_service {
  margin-bottom: 40px;
}
.cont_service {
  display: flex;
  gap: 2%;
  text-align: center;
}
@media (max-width: 833px) {
  .cont_service {
    flex-direction: column;
  }
}
.cont_service .item_service {
  width: 23%;
  border: 1px solid #fff;
  padding: 10px 20px;
  border-radius: 6px;
  transition: all 0.3s;
}
@media (max-width: 833px) {
  .cont_service .item_service {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
}
.cont_service .item_service:hover {
  border-color: #00338d;
  background-color: #f9f9f9;
}
.cont_service .item_service img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-bottom: 50px;
}
.cont_service .item_service h2 {
  color: #333;
  margin-top: 70px;
  font-weight: bold;
}

.cont_service .item_service p {
  line-height: 1.6;
  font-size: 17px;
  color: #444;
  padding: auto;
}
@media (max-width: 833px) {
  .cont_service .item_service h2 {
    margin-top: 0;
  }
  .cont_service .item_service p {
    line-height: 1.9;
    font-size: 17px;
    color: #444;
    padding: 10px 20px 0;
  }
}
/* End Service */

/* Start Join */
.content_join {
  align-items: stretch;
  display: flex;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 833px) {
  .content_join {
    flex-direction: column;
    text-align: center;
  }
  .content_join .img_join,
  .contect_join {
    width: 100%;
  }
}
/* .ccontainers {
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
  padding-top: 2rem;
  position: relative;
} */
.contect_join {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5rem;
  flex-grow: 1;
  max-width: calc(960px + 2rem);
  min-width: 0;
  padding: 2rem 3.5rem 4.5rem;
  width: 50%;
}
.img_join {
  flex-grow: 1;
  max-width: calc(960px + 2rem);
  /* min-height: calc(100vh - 5rem); */
  position: relative;
  width: 50%;
}

@media (max-width: 833px) {
  .contect_join {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
}
.img_join img {
  width: 700px;
  height: 706px;
}
.contect_join h2 {
  margin-bottom: 2rem;
  font-size: 55px;
  font-weight: 300;
  line-height: 1.1;
  color: #464b4b;
  font-family: sans-serif;
  text-align: left;
}
@media (max-width: 833px) {
  .contect_join h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
}
.contect_join p {
  line-height: 26px;
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
  color: #464b4b;
}
.contect_join span {
  cursor: pointer;
  display: inline-flex;
  font-size: 1.5rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  max-width: 100%;
  min-height: 3rem;
  min-width: 300px;
  overflow: hidden;
  padding: 0.75rem 1.5rem;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s;
  vertical-align: middle;
  border-bottom-left-radius: 1.65rem;
  border-top-right-radius: 1.65rem;
  align-items: center;
  border: 1px solid #094191;
}
.contect_join span:hover {
  background-color: #00338d;
  color: #fff;
}
/* End Join */

/* Start Style Card */

:root {
  --surface-color: #fff;
  --curve: 40;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fef8f8;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 0;
  list-style-type: none;
}
@media (max-width: 833px) {
  .section .section-container,
  .scontainer {
    width: 580px;
  }
  .cards {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
  }
  .card__image {
    width: 100%;
  }
}

.card {
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__image {
  width: 100%;
  height: 490px;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path('M 40 80 c 22 0 40 -22 40 -40 v 40 Z');
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  font-size: 1.3rem;
  margin: 0 0 0.3em;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid #fff;
  word-break: break-word;
  transition: all 0.3s;
}
.card:hover .card__title {
  color: #e0301e;
  border-bottom: 2px solid #e0301e;
}
.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: 'MockFlowFont';
  font-size: 0.8em;
  color: #d7bdca;
}

.card__status {
  font-size: 0.8em;
  color: #d7bdca;
}

.card__description {
  font-size: 1rem;
  padding: 0 2em 2em;
  margin: 0;
  color: #777;
  font-family: 'MockFlowFont';
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

/* End Style Card */

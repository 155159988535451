* {
  box-sizing: border-box;
}

:root {
  --main-transition: 0.3s;
}

@media (min-width: 1200px) {
  .container_s {
    width: 1170px;
    margin: 0 auto;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Cairo', sans-serif;
}

a {
  text-decoration: none !important;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.Header_s {
  background-color: #fff;
  position: fixed;
  z-index: 222;
  width: 100%;
  padding: 8px;
  box-shadow: 0 0 10px #ddd;
}

.Header_s .container_s {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}

.Header_s .logo {
  color: steelblue;
  font-size: 26px;
  font-weight: bold;
  height: 72px;
  width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_s .logo img {
  width: 100%;
}

@media (max-width: 833px) {
  .Header_s .container_s {
    flex-direction: column;
  }
  .Header_s .logo {
    width: 145px;
    height: 63px;
  }
}

@media (max-width: 767px) {
  .Header_s .logo {
    width: 100px;
    height: 50px;
  }
  .Header_s .main_nav {
    margin: auto;
  }
}

.Header_s .main_nav {
  display: flex;
}

.Header_s .main_nav > li > a {
  height: 72px;
  position: relative;
  color: black;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  overflow: hidden;
  transition: var(--main-transition);
}

@media (max-width: 767px) {
  .Header_s .main_nav > li > a {
    padding: 8px;
    font-size: 13px;
    height: 40px;
  }
}
.Header_s .main_nav > li > a::before {
  content: '';
  width: 100%;
  height: 4px;
  background-color: blue;
  position: absolute;
  top: 0;
  left: -100%;
  transition: var(--main-transition);
}

.Header_s .main_nav > li:hover .mega_menu {
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  top: calc(100% + 1px);
}

.Header_s .main_nav > li > a:hover {
  background-color: #f2f2f2;
  color: steelblue;
}

.Header_s .main_nav > li > a:hover::before {
  left: 0;
}

.Header_s .mega_menu {
  width: 100%;
  left: 0;
  padding: 30px;
  background-color: #fff;
  color: black;
  border-bottom: 3px solid blue;
  display: flex;
  gap: 40px;
  z-index: -10;
  top: calc(100% + 50px);
  transition: top var(--main-transition), opacity var(--main-transition);
  flex-wrap: wrap;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 767px) {
  .Header_s .mega_menu {
    flex-direction: column;
    gap: 0;
    padding: 5px;
  }
}
.Header_s .mega_menu .image {
  width: 480px;
}
.Header_s .mega_menu .image img {
  max-width: 100%;
}
@media (max-width: 991px) {
  .Header_s .mega_menu .image {
    display: none;
  }
}
.Header_s .mega_menu .links {
  min-width: 250px;
  flex: 1;
}
.Header_s .mega_menu .links li {
  position: relative;
}
.Header_s .mega_menu .links li:not(:last-child) {
  border-bottom: 1px solid #e9e6e6;
}
.Header_s .mega_menu .links li::before {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #f2f2f2f2;
  left: 0;
  top: 0;
  z-index: -1;
  transition: var(--main-transition);
}
.Header_s .mega_menu .links li:hover::before {
  width: 100%;
}
.Header_s .mega_menu .links li a {
  display: block;
  padding: 25px;
  color: blue;
  font-size: 18px;
  font-weight: bold;
}
.Header_s .mega_menu .links li a svg {
  margin-right: 10px;
}
/* End Header */

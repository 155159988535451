body {
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  background-color: var(--veryLightGray);
}

.slick-list,
.slick-slider {
  display: block;
  position: relative;
}
.slick-slider {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.HeroSlider {
  color: #fff;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translateZ(0);
}
.slick-list {
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.slick-list,
.slick-slider {
  display: block;
  position: relative;
}
.slick-opacity {
  opacity: 1;
  width: 1920px;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translateZ(0);
}

element {
  opacity: 1;
  width: 1920px;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translateZ(0);
}
.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
}
.slick-slide {
  width: 1920px;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  opacity: 1;
}
.slick-initialized .slick-slide {
  display: block;
}
.HeroSlider-slide {
  height: 500px;
  padding: 10rem 5rem 5rem;
  position: relative;
}
.slick-slide {
  display: none;
  float: left;

  min-height: 1px;
}
.HeroSlider-cover {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.HeroSlider-image {
  background-size: cover;
  display: block;
  height: 100%;
  width: 100%;
}
.imgs {
  background-image: url(/public/image-34.jpg);
}

.HeroSlider-cover span {
  background-color: aqua;
  width: 100%;
  height: 500px;
}
.cont_jo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3%;
}

.img_join,
.cont_join {
  width: 47%;
}
.img_join > img {
  width: 100%;
  height: 585px;
}
.cont_jo {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 60px 20px;
  transition: all 0.3s;
}
.our_join h1 {
  margin: 40px 0;
}
.inf_join {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  transition: all 0.3s;
}
.inf_join lable {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.inf_join input,
textarea {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
  outline: none;
  transition: all 0.3s;
  font-size: 16px;
  height: 36px;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  box-shadow: none !important;
  border-radius: 2px;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;
}
.inf_join input:focus {
  border-color: hsl(218, 100%, 50%);
}

.inf_join input.sub:hover {
  background-color: hsl(218, 100%, 50%);
  transition: all 0.3s;
  font-size: 20px;
  color: #fff;
}
